import { useEffect,useState,useRef} from "react";
import {SlPaperPlane} from "react-icons/sl";
import {useLocation,useNavigate } from 'react-router-dom';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { IoIosArrowBack } from "react-icons/io";
import { motion } from "framer-motion";
import dayjs  from 'dayjs';
import { useSelector, useDispatch } from "react-redux";
import styles from "./Messages.module.scss";
import Layout from "../components/ui/Layout";
import Loader from "../components/ui/Loader";
import { ReactComponent as HomeChat } from '../assets/images/Menu-Icon-Chat.svg';
import { Endpoints } from "../core/networking";
import * as Actions from "./state/message/actions";
import * as BGActions from "./state/background/actions";
import { debounce } from "lodash";
import { getFileTypeIcon, getMimeType } from "../components/util";
import * as Action from "./state/additionalinfo/actions";
import { format } from "date-fns";
import { DATE_TIME_FORMAT } from "../utils/constants";
import BackgroundFetch from "../pages/components/BackgroundFetch";

dayjs.extend(customParseFormat);
const MessageDetails = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const {
        notificationData: { addMessage, messageInfo, fileDownload },
        homeData: { getNotificationInfo },
        bgData: {  notifications }
	} = useSelector((state) => state);
    const { response: downloadedFile, isLoading: fileLoading } = fileDownload || {};
	const { response: addedMsg, isLoading: addMsgloading } = addMessage || {};
    const { response: messagedInfo, isLoading: messagedlLoading } = messageInfo || {};

    const { response: notificationsList } = notifications || {};

    const [msgInput, setMsgInput] = useState("");
    const navigate = useNavigate();
    const [msgInfo,setMsgInfo] = useState([]);
    const threadInfo = useRef([]);
    const [repliedToMessageId, setRepliedToMessageId] = useState(0);
	const [scrollbarHeight,setScrollBarHeight] = useState(window.screen.availHeight);
    const msgInputRef = useRef();
    const inputSpanElm = useRef();
    const [fileData, setFileData] = useState(null);
    const [, setMsgOverFlow] = useState(false);
    const [, setMsgBoxHeight] = useState(150);
    const { response: notificationInfo } = getNotificationInfo || {};
    const [notificationContent, setNotificationContent] = useState([]);

    const animations = {
        initial: {opacity: 0, x: 100},
        animate: {opacity: 1, x: 0,transition:{duration:0.5}},
        exit: {opacity: 0, x :0}
    }

    const getContentHeight = () => {
        let mainElement = document.getElementsByClassName("app");
        let subject = document.querySelector(".subjectDiv");
        let inputSection = document.querySelector(".inputBottomSection");
        // take into account recipient name bar height
        let recipientBar = document.querySelector('.recipientBar');
        let subjectHeight = subject ? subject.clientHeight : 0;
        let recipeintBarHeight = recipientBar ? recipientBar.clientHeight : 0;

        return mainElement[0]?.offsetHeight - subjectHeight - inputSection.clientHeight - recipeintBarHeight - 12;
    }

    
    useEffect(() => {
        // set scroll window height
		setScrollBarHeight(getContentHeight());

        const debouncedHandleResize = debounce(function handleResize() {
            setScrollBarHeight(getContentHeight());
        }, 300);

        window.addEventListener("resize", debouncedHandleResize);
        

        // cleanup listener
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
	}, [scrollbarHeight, messagedInfo, msgInput]);


    const fetchMessageInfo = async () => {
        if(location?.state)
        {
            let { msgInfo } = location?.state;
            threadInfo.current = msgInfo;
            let threadId = msgInfo?.ThreadID;
            const timeOffset = (new Date()).getTimezoneOffset();
            if(threadId !== ""){
                const taskEndPoint = Endpoints.GET_MSG_INFO_LIST + threadId + '/' + timeOffset;
                let params = {endpoint: taskEndPoint,method: "GET"};
                dispatch(Actions.getMsgInfoRequest(params));
            }
        }
    }
    

    useEffect(() => {
        document.title = 'Message Screen';
        dispatch(Action.fileDownloadClear());
        dispatch(BGActions.notificationInfoClear());
        fetchMessageInfo();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps




    useEffect(() => {
        if ((notificationsList !== null && notificationsList !== undefined) || (notificationInfo !== null && notificationInfo !== undefined)) {
            let notifications = notificationsList || notificationInfo;

            if (notifications !== null && notifications !== undefined) {

                let taskNotifications = [];

                let notification = {
                    unreadMsgs: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 2 && notification.IsAcknowledged === false)),
                    newTasks: taskNotifications?.filter((notification) => (notification.IsTaskOverdue === false && notification.IsAcknowledged === false)),
                    allTasks: taskNotifications?.filter((notification) => (notification.IsAcknowledged === false)),
                    tasks: taskNotifications?.filter((notification) => (notification.IsTaskOverdue === true)),
                    videos: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 3 && notification.IsAcknowledged === false)),
                    calendar: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 4 && notification.IsAcknowledged === false)),
                }

                if (notification) {
                    setNotificationContent(notification);
                }
            }
        }

    }, [notificationsList, notificationInfo]);


    useEffect(() => {
        setMsgInfo([]);
        if (typeof messagedInfo !== "undefined" && messagedInfo !== null  && messagedInfo) {
            if(messagedInfo.length > 0)
            {
                const messageList = messagedInfo[messagedInfo.length-1];
                if(messageList.Messages.length > 0)
                {
                    const lastMsg = messageList.Messages[messageList.Messages.length-1];
                    setRepliedToMessageId(lastMsg.MessageID);
                }
                let msgreceipientName = messagedInfo[0].MessageReceipients.map(a => a.Name);
                let msgreceipientId = messagedInfo[0].MessageReceipients.map(a => a.ID);
                threadInfo.current.msgReceipientName = msgreceipientName.join(" , ");
                threadInfo.current.msgReceipientId = msgreceipientId.join(",");
            }

            setMsgInfo(messagedInfo);

            if (messagedInfo.length > 1 || messagedInfo[0]?.Messages.length > 1) {
                setTimeout(() => {
                    msgInputRef.current.scrollToBottom();
                }, 50);
            }

            let applicantID = location?.state?.applicantID;

            if (applicantID !== "") {
                // Get Notification
                const notificationEndpoint = Endpoints.NOTIFICATION_GET_DATA + applicantID;//"163354";//applicantID //"163354"
                let notify = {
                    endpoint: notificationEndpoint,
                    method: "GET",
                };
                dispatch(BGActions.notificationInfo(notify));
            }
        }

        
	}, [messagedInfo]);

    useEffect(() => {
        if (typeof addedMsg !== "undefined" && addedMsg) {
            fetchMessageInfo();
            inputSpanElm.current.textContent = "";
            setMsgInput("");
            dispatch(Actions.addMsgClear());
		}
    }, [addedMsg]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            typeof downloadedFile !== "undefined" &&
            downloadedFile !== null &&
            downloadedFile && fileData !=null
        ) {
          
            let binaryString = window.atob(downloadedFile);
            // window.console.log(binaryString)
            let binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);

            for (let i = 0; i < binaryLen; i++) {
                let ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }

            let blob = new Blob([bytes], { type: getMimeType(fileData) });
            let link = URL.createObjectURL(blob);

            dispatch(Action.fileDownloadClear());

            const a = document.createElement('a');
            a.href = link;
            a.download = fileData.FileName; // Specify the file name
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Clean up
            URL.revokeObjectURL(link);
        }
        // eslint-disable-next-line
    }, [downloadedFile]);

    function handleChange(event) {
        setMsgInput(event.target.textContent);
    }

    function setMsgInputBoxHeight() {
        const mainElement = document.getElementsByClassName("app");
        const inputBox = document.getElementsByClassName("inputkeyContainer");
        if (inputBox[0]?.scrollHeight > 0) {
            let maxHeight = 0.50 * mainElement[0]?.clientHeight;
            if (inputBox[0]?.scrollHeight > maxHeight) {
                setMsgBoxHeight(maxHeight);
                setMsgOverFlow(true);
            }
            else {
                setMsgOverFlow(false);
            }
        }
    }

    useEffect(() => {
        setMsgInputBoxHeight();
    }, [msgInput]);
    

    const handleMessageSubmission = async () => {
        const formData = new FormData();
        if(msgInput !== ""){
            formData.append("MessageThreadID",threadInfo.current.ThreadID);
            formData.append("MessageText",inputSpanElm.current.outerText);
            formData.append("IsReply",true);
            formData.append("RepliedToMessageID",repliedToMessageId);
            formData.append("MessageRecipientIDs",threadInfo.current.msgReceipientId);
            formData.append("endpoint",'messages/AddMessage');
            dispatch(Actions.addMsgRequest(formData));
        }
    }

    const Message = ({info}) => {
        let fromMessage = info.Type === "Question";

        return (
            <div  className="mb-2 pb-1">
                <div className={fromMessage ? styles.questionContainer : styles.answerContainer}>
                    { fromMessage &&
                        <div className={styles.msgByName}>
                            {info.Name}
                        </div>
                    }
                    <div className={`d-flex${fromMessage ? '' : ' justify-content-end'}`}>
                        <div className={fromMessage ? styles.questionTag : styles.answerTag}>
                            {info.Content}
                            {info.Attachments?.map(
                                (attachInfo) =>
                                    (
                                        <div key={attachInfo.MessageAttachmentID}
                                             className={`${fromMessage ? 'text-start' : 'text-end'} w-100`}>
                                            <div className={`${styles.attachmentContainer} d-inline-block`}>
                                                <div className={`${styles.attachment}`}>
                                                    <Attachment attachDetails={attachInfo}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                        <div className={fromMessage ? styles.questionTime : styles.answerTime}>
                            
                            {format(new Date(info.MessageDateTime.replace('Z',"")), DATE_TIME_FORMAT.MESSAGES)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const downloadAttachment = async (file) => {
        setFileData(file)
        const taskEndPoint = file.URL;
        let params = {
            endpoint: taskEndPoint,
            mime: getMimeType(file),
            method: "GET",
        };
        dispatch(Action.fileDownloadRequest(params));
    };

    function Attachment(attachment) {
        const attachmentInfo = attachment.attachDetails;
        let filename = attachmentInfo.FileName.split(".").pop().toLowerCase();
        let Icon = getFileTypeIcon(filename);

        return (
            <button
                onClick={async () =>
                    await downloadAttachment(attachmentInfo)
                }
                className={styles.attachmentButton}
            >
                <span className="flex-shrink-0">
                    <Icon />
                </span>
                <span className="text-break text-start lh-sm ms-2">{attachmentInfo.FileName}</span>
            </button>
        )
    }

    function goBack() {
        if (location?.state?.redirect) {
            navigate(location?.state?.redirect, { state: { notifyTaskID: location?.state?.notifyTaskID } });
        } else {
            navigate("/messages");
        }
        
        return false;
    }

    return (
        <>
            {(messagedlLoading || fileLoading || addMsgloading) && <Loader/>}
            <Layout showHeader="false" dueTasksCounter={notificationContent}>
                <BackgroundFetch requestBy={['notify']} />
                <div className={styles.stickyHeader}>
                    <div className={styles.pageTitleSection}>
                        <h1 className="d-flex justify-content-center align-items-center">
                            <span className="me-4">
                                <HomeChat className={styles.headerIcon} />
                            </span>
                            Messages
                        </h1>
                        <div onClick={goBack}>
                            <IoIosArrowBack
                                className={`${styles.backIcon} float-end position-absolute`}
                            />
                        </div>
                    </div>
                    {threadInfo.current.msgReceipientName ?
                        <div className={`${styles.newMessageBar} wrapper px-3 text-white text-center recipientBar`}>
                                {threadInfo.current.msgReceipientName}
                        </div>
                        : null
                    }
                </div>
                <motion.div variants={animations} initial="initial" animate="animate" exit="exit">

                    { (threadInfo.current.Subject && threadInfo.current.Subject !== "undefined" && threadInfo.current.Subject !== "") &&
                        <div className={`subjectDiv ${styles.messageSubject} text-center my-2`}>{threadInfo.current.Subject}</div> }
                    <div className={`position-relative`} id="messageContainer" >
                            <Scrollbars
                                style={{ height: scrollbarHeight - 120 }}
                                autoHeight
                                autoHeightMax={scrollbarHeight - 120}
                                className={`${styles.msgContainer} scroll-container`}
                                ref={msgInputRef}
                            >
                                {
                                    msgInfo && msgInfo.map((message) => {
                                        return <div key={message.MessageDate} className={`${styles.messages} msgDetails`} >
                                            <div className={styles.msgDate} >
                                                {dayjs(message.MessageDate, 'YYYY-MM-DD').format('dddd, MMMM DD')}
                                            </div>
                                            {message.Messages &&
                                                message.Messages.map(
                                                    (msgInfo) => <Message key={msgInfo.MessageID} info={msgInfo} />
                                                )
                                            }
                                        </div>
                                    })
                                }
                            </Scrollbars>
                    </div>

                    <div className="w-100 bg-white position-absolute start-0 end-0 bottom-0">
                        <div className={`inputBottomSection mx-auto ${styles.inputBox} `}>
                            <div className="position-relative">
                               
                                 <Scrollbars
                                    autoHeight
                                    autoHeightMax={400}
                                    className={styles.spanTextArea}
                                >
                                    <div className={styles.spanTextAreaBox + " inputkeyContainer "}
                                          // eslint-disable-next-line
                                          role="textarea"
                                          ref={inputSpanElm}
                                          onInput={handleChange}
                                          contentEditable={true}
                                          suppressContentEditableWarning={true}>
                                    </div>
                                </Scrollbars>


                                <button className={`${styles.inputIcon} ${(msgInput) ? styles.active : ""} `}
                                  onClick={handleMessageSubmission}>
                                    <SlPaperPlane
                                        className={styles.sendMsg}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </motion.div>
        </Layout>
        </>
    );
}

export default MessageDetails;
