import styles from "./TravelDonor.module.scss";
import Layout from "../../components/ui/Layout";
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import Loader from "../../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../state/travel/actions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Endpoints } from "../../core/networking";
import { stateSource } from "../../components/core/stateFile";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import PageTitle from "../components/PageTitle";

dayjs.extend(customParseFormat);

const TravelInfo = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    let { state } = useLocation();
    const travelDetailsRef = useRef();
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const {
        travelData: { travelDetails },
    } = useSelector((state) => state);
    
    const { response: travelDetailsResponse, isLoading: travelLoading } = travelDetails || {};
    const [donorDetailsResponse, setDonorDetailsResponse] = useState({});

    
    useEffect(() => {
        document.title = 'Travel Info';
        Actions.donorListClear();
        setDonorDetailsResponse({});

        if (location?.state?.travelIternaryID) {
            let param;
            let taskEndPoint = "";

            if (location?.state?.page === "donor-details") {
                taskEndPoint = Endpoints.TRAVEL_DONOR_INFO + location?.state?.travelIternaryID;
            } else if (location?.state?.page === "companion-details") {
                taskEndPoint = Endpoints.TRAVEL_COMPANION_INFO + location?.state?.travelIternaryID;
            } else if (location?.state?.page === "donor-itinerary") {
                taskEndPoint = Endpoints.TRAVEL_DONOR_ITENARY + location?.state?.travelIternaryID;
            } else if (location?.state?.page === "companion-itinerary") {
                taskEndPoint = Endpoints.TRAVEL_COMPANION_ITENARY + location?.state?.travelIternaryID;
            } else if (location?.state?.page === "monitoring-sites") {
                taskEndPoint = Endpoints.TRAVEL_MONITORING_SITES + location?.state?.travelIternaryID;
            } else if (location?.state?.page === "retrieve-clinic") {
                taskEndPoint = Endpoints.TRAVEL_RETRIEVAL_SITES + location?.state?.travelIternaryID;
                //taskEndPoint = Endpoints.TRAVEL_RETRIEVAL_CLINIC_DETAILS + loginVal?.data?.applicant_id + "/" + location?.state?.cycleID;
            } else if (location?.state?.page === "screening-clinic-details") {
                taskEndPoint = Endpoints.TRAVEL_SCREENING_SITES + location?.state?.travelIternaryID;
            }
            
            
            if (taskEndPoint !== "") {
                param = { endpoint: taskEndPoint, method: "GET" };
                dispatch(Actions.donorListRequest(param));
            }

        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (travelDetailsResponse && location?.state?.travelIternaryID) {
            try {
                if (travelDetailsResponse instanceof Object) {
                    setDonorDetailsResponse(travelDetailsResponse);
                } else
                    setDonorDetailsResponse(JSON.parse(travelDetailsResponse));
            } catch (error) {
                setDonorDetailsResponse({});
            }
            
            Actions.donorListClear();
        }

    }, [travelDetailsResponse, location?.state?.travelIternaryID]);

    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");
        const footer = document.querySelector(".footerElement");
        const pgTitle = document.querySelector(".homePageTitle");
        setScrollBarHeight(mainElement[0]?.offsetHeight - footer?.offsetHeight - pgTitle?.offsetHeight);
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight - footer?.offsetHeight - pgTitle?.offsetHeight);
        });
    }, [scrollbarHeight]);

    function displayDonorFields(details, index, groupsDetails) {
        let value = "";
        /*if (details?.ItemText) {
            value = details?.ItemText;
        }
        else */
        if (details?.TextValue) {
            value = details?.TextValue;

            if (details?.ItemID === 18) {
                value = dayjs(value).format('DD/MM/YYYY hh:mm A');
            }else if (details?.ItemID === 19) {
                value = dayjs(value).format('DD/MM/YYYY hh:mm A');
            } else if (details?.ItemID === 64) {
                value = dayjs(value).format('DD/MM/YYYY hh:mm A');
            }

        } else if (details?.CheckValue) {
            value = details?.CheckValue;

        } else if (details?.DateValue) {

        } else if (details?.ListValueID) {
            value = details?.ListValueID;
            if (details?.ItemID === 8 || details?.ItemID === 34) // state
            {
                let stateFilter = stateSource.filter(function (state) {
                    return state.code === value;
                });
                value = stateFilter[0]?.stateName;
            }
            else if (details?.ItemID === 40) {
                if (details?.ListValueID === 21) {
                    value = "Mother";
                } else if (details?.ListValueID === 22) {
                    value = "Father";
                } else if (details?.ListValueID === 23) {
                    value = "Son";
                } else if (details?.ListValueID === 24) {
                    value = "Daughter";
                } else if (details?.ListValueID === 25) {
                    value = "Sister";
                } else if (details?.ListValueID === 26) {
                    value = "Brother";
                }
            }
            else if (details?.ItemID === 32) {
                if (details?.ListValueID === 15) {
                    value = "Mother";
                } else if (details?.ListValueID === 16) {
                    value = "Father";
                } else if (details?.ListValueID === 17) {
                    value = "Son";
                } else if (details?.ListValueID === 18) {
                    value = "Daughter";
                } else if (details?.ListValueID === 19) {
                    value = "Sister";
                } else if (details?.ListValueID === 20) {
                    value = "Brother";
                }
            }
            else if (details?.ItemID === 13) {
                if (details?.ListValueID === 1) {
                    value = "Mother";
                } else if (details?.ListValueID === 2) {
                    value = "Father";
                } else if (details?.ListValueID === 3) {
                    value = "Son";
                } else if (details?.ListValueID === 4) {
                    value = "Daughter";
                } else if (details?.ListValueID === 5) {
                    value = "Sister";
                } else if (details?.ListValueID === 6) {
                    value = "Brother";
                }
            }
            else if (details?.ItemID === 63 || details?.ItemID === 36) // state
            {
                value = (details?.ListValueID === 13 ? "Driving" : "Flying");
            }
            else if (details?.ItemID === 4) // state
            {
                value = (details?.ListValueID === 7 ? "Driving" : "Flying");
            }
            else if (details?.ItemID === 41) // state
            {
                value = (details?.ListValueID === 11 ? "Driving" : "Flying");
            }
            else if (details?.ItemID === 79) // state
            {
                value = (details?.ListValueID === 52 ? "Entire Time" : "Just for Egg Retrieval");
            }

        } else if (details?.OptionID) {

        }

        if (value) {
            return (
                (details?.ItemID !== 76 && details?.ItemID !== 77 && details?.ItemID !== 78
                    && details?.ItemID !== 73 && details?.ItemID !== 74 && details?.ItemID !== 75 && details?.ItemID !== 66) &&
                <li key={index} className={`${styles.contentText} ${details?.ItemID} cursor-pointer position-relative`}>
                    <span className={styles.infoHead}>{details?.ItemText}</span>
                    <span className={styles.infoValue}>{value}
                        {
                            (details?.ItemID === 45 || details?.ItemID === 47 || details?.ItemID === 50 || details?.ItemID === 18 ||
                             details?.ItemID === 19 || details?.ItemID === 64) &&
                            getTimeZone(details?.ItemID, groupsDetails)
                        }
                    </span>
                </li>                
            )
        }
    }

    function getTimeZone(itemID = 0, groupsDetails) {
        let timeZoneID = 0;
        if (itemID === 45) {
            timeZoneID = 76;
        }
        else if (itemID === 47) {
            timeZoneID = 77;
        }
        else if (itemID === 50) {
            timeZoneID = 78;
        }
        else if (itemID === 18) {
            timeZoneID = 73;
        }
        else if (itemID === 19) {
            timeZoneID = 74;
        }
        else if (itemID === 64) {
            timeZoneID = 75;
        }

        if (groupsDetails.TravelItineraryAnswers) {
            let timeZoneItinerary = groupsDetails.TravelItineraryAnswers.filter((itinerary) => {
                return itinerary.ItemID === timeZoneID;
            });

            let timeZone = 0;
            if (timeZoneItinerary) {
                timeZone = timeZoneItinerary[0].ListValueID;

                if (timeZone === 28 || timeZone === 32 || timeZone === 36 || timeZone === 40 || timeZone === 44 || timeZone === 48) {
                    return <label> EST </label>
                } else if (timeZone === 41 || timeZone === 29 || timeZone === 33 || timeZone === 27 || timeZone === 45 || timeZone === 49) {
                    return <label> CST </label>
                } else if (timeZone === 42 || timeZone === 30 || timeZone === 34 || timeZone === 238 || timeZone === 46 || timeZone === 50) {
                    return <label> MST </label>
                } else if (timeZone === 43 || timeZone === 31 || timeZone === 35 || timeZone === 39 || timeZone === 47 || timeZone === 51) {
                    return <label> PST </label>
                }
            }
        }
    }

    return (
        <>
            {(travelLoading) && <Loader />}
            <Layout showHeader="false"> 
                <>
                    <div className={styles.stickyHeader}>
                        <PageTitle
                            title={location?.state?.title}
                            headerClass="homePageTitle"
                            linkTo={state?.prevPage ? state?.prevPage : "/home"}
                            linkState={{ menu: state?.menu }}
                        />
                    </div>
                    <div className={styles.travelDetailsContainer}>
                        <Scrollbars
                            autoHeight
                            autoHeightMax={scrollbarHeight}
                            className={`${styles.travelContainer}`}
                            ref={travelDetailsRef}
                        >
                            {
                                (!travelLoading && donorDetailsResponse) &&
                                <div className={styles.wrapper}>
                                    <div>
                                            {
                                                donorDetailsResponse.Section &&
                                                    <span className={styles.subHeading}>
                                                        {donorDetailsResponse?.Section}
                                                    </span>
                                            }
                                            {
                                                ((location?.state?.page === "retrieve-clinic" || location?.state?.page === "screening-clinic-details") && donorDetailsResponse?.LocationName) &&
                                                <span className={styles.subHeading}>
                                                    Clinic Location
                                                </span>
                                            }
                                            {
                                                (location?.state?.page === "monitoring-sites") &&
                                                <span className={styles.subHeading}>
                                                    Site Details
                                                </span>
                                            }
                                            {
                                                (location?.state?.page === "retrieve-clinic" || location?.state?.page === "screening-clinic-details") &&
                                                <>
                                                    
                                                    {
                                                        donorDetailsResponse?.LocationName &&
                                                        <div>{donorDetailsResponse?.LocationName}</div>
                                                    }
                                                    {
                                                        donorDetailsResponse?.Phone &&
                                                        <div>{donorDetailsResponse?.Phone}</div>
                                                    }
                                                    {
                                                        donorDetailsResponse?.Address &&
                                                        <div>{donorDetailsResponse?.Address}</div>
                                                    }
                                                    <div style={{marginBottom:"30px"} }>
                                                        {
                                                            donorDetailsResponse?.City &&
                                                            <>{donorDetailsResponse?.City}</>
                                                        }
                                                        {
                                                            donorDetailsResponse?.State &&
                                                            <>,&nbsp;{donorDetailsResponse?.State}</>
                                                        }
                                                        {
                                                            donorDetailsResponse?.ZipCode &&
                                                            <>,&nbsp;{donorDetailsResponse?.ZipCode}</>
                                                        }
                                                    </div>
                                                    {
                                                        donorDetailsResponse?.Appointments?.length > 0 &&
                                                        <>
                                                            <h5 className={styles.subHeading} style={{ marginTop: "20px" }}>Appointments</h5>
                                                            <ul style={{ marginTop: "20px" }}>
                                                                {
                                                                    donorDetailsResponse?.Appointments.map((appointments, index) => {
                                                                        return (
                                                                            <li key={index} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                                <div className={styles.appointmentTime}>{appointments?.AppointmentDateTime}</div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </>
                                                        
                                                    }
                                                </>
                                            }
                                            {
                                                location?.state?.page === "monitoring-sites" &&
                                                <>
                                                    <ul>
                                                        {
                                                            donorDetailsResponse.TravelAnsList &&
                                                            donorDetailsResponse.TravelAnsList.map((monitoring, index) => {
                                                                
                                                                return (
                                                                    monitoring?.TextValue &&
                                                                    <li key={index} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                        {
                                                                            monitoring?.ItemName &&
                                                                            <div className={styles.infoHead}>{monitoring?.ItemName}</div>
                                                                        }
                                                                        {
                                                                            monitoring?.TextValue &&
                                                                            <div className={styles.infoValue}>{monitoring?.TextValue}</div>
                                                                        }
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </>
                                            }
                                            {
                                                donorDetailsResponse.TravelItineraryAppointments &&
                                                donorDetailsResponse.TravelItineraryAppointments.map((appointments,index) => {
                                                    return (
                                                        <div className={styles.detailsinfo} key={index}>
                                                            <h5>Appointment {index+1}</h5>
                                                            <ul>
                                                                {
                                                                    appointments?.AppointmentDate &&
                                                                    <li key={0}>
                                                                            {dayjs(appointments?.AppointmentDate).format("MMMM DD,YYYY")}<br />
                                                                            {dayjs(appointments?.AppointmentTime).format("hh:mm a")}<br />
                                                                    </li>
                                                                }
                                                                {
                                                                    appointments?.ClinicAddress &&
                                                                    <li key={1}>{appointments?.ClinicAddress}</li>
                                                                }
                                                                {
                                                                    appointments?.ClinicPhone &&
                                                                    <li key={2}>{appointments?.ClinicPhone}</li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    );
                                                })
                                            }
                                            {
                                                donorDetailsResponse?.TravelItineraryContacts?.length > 0 &&
                                                <div className={styles.detailsinfo} style={{paddingLeft:"0px",marginLeft:"0px"} }>
                                                        <h5>
                                                            Contacts
                                                        </h5>
                                                        <ul>
                                                            {
                                                                donorDetailsResponse.TravelItineraryContacts.map((contacts, index) => {
                                                                    return (
                                                                        <li key={index} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                            {
                                                                                contacts.Name &&
                                                                                <span className={styles.infoValue}>{contacts.Name}</span>
                                                                            }
                                                                            {
                                                                                contacts.Phone &&
                                                                                <span className={styles.infoValue}>{contacts.Phone}</span>
                                                                            }
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                        </ul>
                                                </div>
                                            }
                                            
                                            {   donorDetailsResponse.Groups &&
                                                donorDetailsResponse.Groups.map((groupsDetails, index) => {
                                                    
                                                        return (
                                                            <div className={styles.detailsinfo} key={index}>
                                                               <h5>
                                                                  {groupsDetails?.GroupName}
                                                                </h5>
                                                                <ul>
                                                                    {
                                                                        groupsDetails.TravelItineraryAnswers &&
                                                                        groupsDetails.TravelItineraryAnswers.map((details,index) => {
                                                                            return (
                                                                                <>
                                                                                    {displayDonorFields(details, index, groupsDetails)}
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        );
                                                    })
                                             }
                                    </div>
                                </div>
                            }

                        </Scrollbars>
                    </div>
                </>

            </Layout>
        </>
    );
}

export default TravelInfo;
