import Footer from "./Footer";
import Header from "./Header";
import { useEffect, useState } from "react";

import styles from './Layout.module.scss';

function Layout(props) {
    const [showHeader, setShowHeader] = useState(true);
    const [showFooter, setShowFooter] = useState(true);

    useEffect(() => {
        props.showHeader &&
            setShowHeader(props.showHeader === "true");

        props.showFooter &&
            setShowFooter(props.showFooter === "true");

        const elm = document.getElementsByTagName("main");
        if(elm.length > 0 && 'callback' in props) {
            elm[0].removeEventListener('scroll', handleScroll);
            elm[0].addEventListener('scroll', handleScroll);
        }
        
        // eslint-disable-next-line
    },[]);

    const handleScroll = (e) => {
        props.callback(e);
    };

    return (
        <>
            { showHeader &&
                <Header dueTasksCounter={props.dueTasksCounter}/>
            }
            <main id="main" className={`${styles.content} ${props.classes}`}>
                { props.children }
            </main>
            { showFooter &&
                <Footer dueTasksCounter={props.dueTasksCounter} />
            }
        </>
    )
}

export default Layout;
