import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsDot, BsXLg, BsChevronLeft, BsPlus, BsX } from "react-icons/bs";
import { SlPaperPlane } from "react-icons/sl";
import { BiPencil } from "react-icons/bi";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Scrollbars } from "react-custom-scrollbars-2";
import Loader from "../components/ui/Loader";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Messages.module.scss";
import Layout from "../components/ui/Layout";
import { ReactComponent as HomeChat } from "../assets/images/Menu-Icon-Chat.svg";
import * as Actions from "./state/message/actions";
import * as BGActions from "./state/background/actions";
import { Endpoints } from "../core/networking";
import { debounce } from "lodash";
import { format } from "date-fns";
import { toLocalDateTime } from "../utils";
import { DATE_TIME_FORMAT } from "../utils/constants";
import BackgroundFetch from "../pages/components/BackgroundFetch";

dayjs.extend(customParseFormat);
const Messages = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {
        loginData: { loginUser },
        notificationData: { msgList, msgUserList, addMessage },
        homeData: { getNotificationInfo },
        bgData: { messages, notifications }
    } = useSelector((state) => state);
    const { response: messageLists, isLoading: loading } = msgList || {};
    const { response: bgMessageLists } = messages || {};
    const { response: msgGetUserList, isLoading: msgLoading } =
        msgUserList || {};
    const { response: loginVal } = loginUser || {};
    const { response: addedMsg, isLoading: addedMsgLoading } = addMessage || {};
    const { response: notificationsList } = notifications || {};

    const [subjectInput, setSubjectInput] = useState("");
    const [msgInput, setMsgInput] = useState("");
    const navigate = useNavigate();
    // const [isLoading, setIsLoading] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [showCreatePage, setShowCreatePage] = useState(false);
    const [storeMessageUser, setStoreMessageUser] = useState([]);
    const [contactTxtBox, setContactTxtBox] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [messageList, setMessageList] = useState([]);
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const msgInputRef = useRef();
    const subjectElm = useRef();
    const msgElm = useRef();
    const [, setMsgOverFlow] = useState(false);
    const [, setMsgBoxHeight] = useState(0);
    const [notificationContent, setNotificationContent] = useState([]);
    const { response: notificationInfo } = getNotificationInfo || {};

    const animations = {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 },
    };

    useEffect(() => {
        document.title = "Message Screen";
        dispatch(Actions.addMsgClear());
        dispatch(BGActions.taskFetchClear());
        dispatch(BGActions.notificationInfoClear());
        
        let applicantID = loginVal?.data?.applicant_id;
        if (applicantID !== "") {

            const taskEndPoint =
                Endpoints.MESSAGE_GET_MSG_USER_LIST + applicantID;
            let params = { endpoint: taskEndPoint, method: "GET" };
            dispatch(Actions.getMsgUserListRequest(params));

            const taskEndPoints = Endpoints.MESSAGE_GET_MSG_LIST + applicantID;
            let paramss = { endpoint: taskEndPoints, method: "GET" };
            // get tasks data
            dispatch(Actions.msgGetListRequest(paramss));
        }
        if(location?.state?.newMessage)
        {
            setShowCreatePage(location?.state?.newMessage);
            setStoreMessageUser(location?.state?.receipients);
        }
        if (location?.state?.messageSubject)
        {
         
            setSubjectInput(location?.state?.messageSubject)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if ((notificationsList !== null && notificationsList !== undefined) || (notificationInfo !== null && notificationInfo !== undefined)) {
            let notifications = notificationsList || notificationInfo;

            if (notifications !== null && notifications !== undefined) {

                let taskNotifications = [];
                
                let notification = {
                    unreadMsgs: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 2 && notification.IsAcknowledged === false)),
                    newTasks: taskNotifications?.filter((notification) => (notification.IsTaskOverdue === false && notification.IsAcknowledged === false)),
                    allTasks: taskNotifications?.filter((notification) => (notification.IsAcknowledged === false)),
                    tasks: taskNotifications?.filter((notification) => (notification.IsTaskOverdue === true)),
                    videos: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 3 && notification.IsAcknowledged === false)),
                    calendar: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 4 && notification.IsAcknowledged === false)),
                }

                if (notification) {
                    setNotificationContent(notification);
                }
            }
        }

    }, [notificationsList, notificationInfo]);

    useEffect(() => {
        let threads = bgMessageLists || messageLists;
        dispatch(BGActions.notificationInfoClear());

        if (typeof threads !== "undefined" && threads) {
            threads.sort(function (a, b) {
                return (
                    new Date(b.MessageDateTime) - new Date(a.MessageDateTime)
                );
            });
            setMessageList(threads);
          
        }

        // Get Notification
        let applicantID = loginVal?.data?.applicant_id;
        if (applicantID !== "") {
            const notificationEndpoint = Endpoints.NOTIFICATION_GET_DATA + applicantID;//"163354";//applicantID //"163354"
            let notify = {
                endpoint: notificationEndpoint,
                method: "GET",
            };
            dispatch(BGActions.notificationInfo(notify));
        }
    }, [messageLists, bgMessageLists]);

    useEffect(() => {

        setShowContactModal(false);
        if (location?.state?.showCoordinators && !loading) {
           setShowContactModal(true);
        }

    }, [loading, location?.state?.showCoordinators]);

    useEffect(() => {
        if (typeof msgGetUserList !== "undefined" && msgGetUserList) {
            setContactList(msgGetUserList);
        }
    }, [msgGetUserList]);

    useEffect(() => {
        if (typeof addedMsg !== "undefined" && addedMsg) {
            setMsgInput("");
            setSubjectInput("");
            navigate("/messages/detail", { state: { msgInfo: addedMsg, redirect: location?.state?.redirect, notifyTaskID: location?.state?.notifyTaskID, applicantID: loginVal?.data?.applicant_id } });
            dispatch(Actions.addMsgClear());
        }
    }, [addedMsg]);// eslint-disable-line react-hooks/exhaustive-deps

    const moveToMsg = (msgthread) => {
        navigate("/messages/detail", { state: { msgInfo: msgthread, applicantID: loginVal?.data?.applicant_id } });
    };

    const getContentHeight = () => {
        const mainElement = document.getElementsByClassName("app");
        const headerElement = document.querySelector(".headerElement");
        const footerElement = document.querySelector(".footerElement");

        // 5px is additional gap added to prevent app container scroll
        return  mainElement[0]?.offsetHeight-(headerElement?.offsetHeight + footerElement?.offsetHeight + 5);
    }

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            console.log('resize - ', getContentHeight());
            setScrollBarHeight(getContentHeight());
        }, 300);

        // set scroll height
        setScrollBarHeight(getContentHeight());
        window.addEventListener("resize", debouncedHandleResize);

        // cleanup listener
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
    }, [scrollbarHeight]);

    const formatMsgDate = (msgDate) => {
        const currentdate = dayjs(new Date());
        const diffDays = currentdate.diff(msgDate, "day");

        if (diffDays === 0) {
            return format(toLocalDateTime(msgDate), DATE_TIME_FORMAT.MESSAGES);
        } else if (diffDays <= 7) {
            return dayjs(msgDate).format("dddd");
        }

        return dayjs(msgDate).format("MM/DD/YYYY");
    };

    const showContacts = (status) => {
        setContactTxtBox("");
        let selectedContacts = contactList.map((obj) => {
            obj.selected = false;
            return obj;
        });
        setContactList(selectedContacts);
        setShowContactModal(status);
    };

    function updateStoreMessageUser(selectedContacts) {
        let MessageUser = selectedContacts.filter(function (itm) {
            return itm.selected === true;
        });
        setStoreMessageUser(MessageUser);
    }

    const removeContacts = (contacts) => {
        let index = contactTxtBox.indexOf(contacts.Name);
        if (index > -1) {
            contactTxtBox.splice(index, 1);
        }
        setContactTxtBox(contactTxtBox);
        let selectedContacts = contactList.map((obj) => {
            if (obj.ID === contacts.ID) {
                obj.selected = false;
            }
            return obj;
        });
        setContactList(selectedContacts);
        updateStoreMessageUser(selectedContacts);
    };

    const addContacts = (contacts) => {
        
        let selectedContacts;
        //makes the contact selected to show cross mark
        selectedContacts = contactList.map((obj) => {
            if (obj.ID === contacts.ID) {
                obj.selected = true;
            }
            return obj;
        });
        setContactList(selectedContacts);
        setContactTxtBox([...contactTxtBox, contacts.Name]);
        updateStoreMessageUser(selectedContacts);
    };

    function handleSubjectChange(event) {
        if (event.target.textContent === "") {
            subjectElm.current.innerHTML = "";
        }
        setSubjectInput(event.target.textContent);
    }

    function handleMessageChange(event) {
        if (event.target.textContent === "") {
            msgElm.current.innerHTML = "";
        }
        setMsgInput(event.target.textContent);
    }

    const previousScreen = () => {
        if(location?.state?.redirect)
        {
            navigate(location?.state?.redirect);
        }else {
            navigate("/home");
        }
    };

    const moveToMsgList = () => {
        if (location?.state?.redirect) {
            navigate(location?.state?.redirect);
        } else {
            setShowContactModal(false);
            setShowCreatePage(false);
        }
    };

    const handleMessageSubmission = () => {
        const formData = new FormData();       

        let msgreceipientName = storeMessageUser.length > 0
                                ? storeMessageUser
                                    .map(a => a.ID)
                                    .filter(value => value !== undefined)
                                    .join(',') : "";


        formData.append("MessageText", msgElm.current.outerText); //msgInput
        formData.append("IsReply", false);
        formData.append("Attachments", null);
        formData.append("MessageThreadID", "");
        formData.append("RepliedToMessageID", "");
        formData.append("ApplicantID", loginVal?.data?.applicant_id);
        formData.append("MessageSubject", subjectElm.current.outerText); //subjectInput
        formData.append("MessageRecipientIDs", msgreceipientName);
        formData.append("endpoint", "messages/AddNewMessageThread");

        dispatch(Actions.addMsgRequest(formData));
    };

    function handleContact(event) {
        setContactTxtBox(event.target.value);
    }

    function handleDone() {
        if(contactTxtBox !== "")
        {
            setShowContactModal(false);
            setShowCreatePage(true);
        }
    }

    function setMsgInputBoxHeight() {
        const mainElement = document.getElementsByClassName("app");
        const inputBox = document.getElementsByClassName("inputkeyContainer");
        if (inputBox[0]?.scrollHeight > 0) {
            let maxHeight = 0.50 * mainElement[0]?.clientHeight;
            if (inputBox[0]?.scrollHeight > maxHeight) {
                setMsgBoxHeight(maxHeight);
                setMsgOverFlow(true);
            }
            else {
                setMsgOverFlow(false);
            }
        }
    }

    useEffect(() => {
        setMsgInputBoxHeight();
    }, [msgInput, subjectInput]);

    const MessageItem = ({message}) => {
        return (
            <div className={`${styles.messages} ${styles.msgList} msgList`}
                 onClick={() => moveToMsg(message)}>
                <div className={styles.newMsg}>
                    { message.Unread &&
                        <BsDot className={styles.msgNew}/> }
                </div>
                <div className={styles.msgContent}>
                    <span className={`${styles.msgfrom} text-truncate`}>
                        {message.MessageRecipientsNames}
                    </span>
                    {(message.Subject && message.Subject !== "undefined" && message.Subject !== "") &&
                        <span className={message.Unread ? styles.subjectnew : styles.subject}>
                            {message.Subject}
                        </span>
                    }
                    <span className={styles.msgtxt}>
                        {message.Content}
                    </span>
                </div>
                <div className={styles.msgDates}>
                    {formatMsgDate(new Date(message.MessageDateTime))}
                    &nbsp;
                    <IoIosArrowForward className={styles.msgContentIcon}/>
                </div>
            </div>
        );
    }

    return (
        <>
            {(msgLoading || loading || addedMsgLoading) && <Loader />}
            <Layout showHeader="false" dueTasksCounter={notificationContent}>
                <BackgroundFetch requestBy={['messages']} />
                {showContactModal && (
                    <>
                        <div className={styles.modalOverlay}></div>
                        <div className={styles.contactModal}>
                            <div className={styles.modalContent}>
                                <BsXLg
                                    onClick={() => showContacts(false)}
                                    className={`${styles.closeIcon} position-absolute`}
                                />
                                <div className={styles.contactTitle}>Contacts</div>
                                <div
                                    className={styles.contactSearchBox}
                                    style={{ textAlign: "center" }}
                                >
                                    <span style={{float:"left"}}>To:</span>&nbsp;&nbsp;
                                    {
                                        contactTxtBox &&
                                        <span className={styles.messageNames} >
                                            {contactTxtBox.join(", ")}
                                        </span>
                                    }
                                </div>
                                <div style={{display:"none"}}>
                                        <input
                                            type="text"
                                            readOnly
                                            className={styles.contactBox}
                                            placeholder="Contact"
                                            name="contactBox"
                                            value={contactTxtBox}
                                            onChange={handleContact}
                                        />
                                </div>
                                <ul>
                                    <Scrollbars autoHeight autoHeightMax={400} className="scroll-container">
                                        { contactList?.map((contacts) =>
                                            (
                                                <li key={contacts.ID}
                                                    className="cursor-pointer"
                                                    onClick={(contacts.selected ? ()=>removeContacts(contacts) : ()=>addContacts(contacts))}
                                                >
                                                    {contacts.Name}
                                                    {contacts.selected ? (
                                                        <BsX onClick={() => removeContacts(contacts)}
                                                             className={`${styles.messagerRemove} position-absolute end-0 cursor-pointer`}
                                                        />
                                                    ) : (
                                                        <BsPlus onClick={() => addContacts(contacts)}
                                                                className={`${styles.messagerAdd} position-absolute end-0 cursor-pointer`}
                                                        />
                                                    )}
                                                </li>
                                            )
                                        )}
                                    </Scrollbars>
                                </ul>
                                <div style={{ textAlign: "center" }}>
                                    <button
                                        style={{ borderRadius: "20px" }}
                                        className={`position-relative mt-4 px-4 py-2 ${styles.doneButton} ${contactTxtBox === "" ? styles.buttonDisabled : ""
                                    }`}
                                        onClick={(contactTxtBox !== "" ? ()=>handleDone() : ()=>null)}
                                    >
                                        Start Message
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {!showCreatePage && (
                    <>
                        <div className={`${styles.stickyHeader} headerElement`}>
                            <div className={styles.pageTitleSection}>
                                <h1 className="d-flex justify-content-center align-items-center">
                                    <span className="me-4">
                                        <HomeChat className={styles.headerIcon} />
                                    </span>
                                    Messages
                                </h1>
                                <a href={() => false} onClick={() => previousScreen()}>
                                     <IoIosArrowBack
                                        className={`${styles.backIcon} float-end position-absolute`}
                                    />
                                </a>
                            </div>
                            <div className={`${styles.newMessageBar} wrapper position-relative px-3 text-center`}>
                                <div className={styles.newMessageTitle}>Conversation</div>
                            </div>
                        </div>
                        <div className={`position-relative`} id="messageContainer">
                            <Scrollbars
                                autoHeight
                                autoHeightMax={scrollbarHeight}
                                className={`${styles.msgContainer} scroll-container`}
                                ref={msgInputRef}
                            >
                                { messageList ?
                                    messageList.map((message) => <MessageItem key={message.ThreadID} message={message} />)
                                    : null
                                }
                            </Scrollbars>
                        </div>
                        <div onClick={() => showContacts(true)} className={`${styles.iconView} position-absolute cursor-pointer rounded-circle d-flex align-items-center justify-content-center`}>
                            <BiPencil
                                className={styles.bottomIcon}
                            />
                        </div>
                    </>
                )}
                {showCreatePage && (
                    <>
                        <motion.div
                            className={styles.createMsgContainer}
                            variants={animations}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            <div className={`${styles.stickyHeader} headerElement`}>
                                <div className={styles.pageTitleSection}>
                                    <h1 className="d-flex justify-content-center align-items-center">
                                        <span className="me-4">
                                            <HomeChat className={styles.headerIcon} />
                                        </span>
                                        Messages
                                    </h1>
                                    <a href={() => false} onClick={() => moveToMsgList()}>
                                        <BsChevronLeft
                                            className={`${styles.backIcon} float-end position-absolute`}
                                        />
                                    </a>
                                </div>
                                <div
                                    className={`${styles.newMessageBar} wrapper px-3 py-2`}
                                    style={{ textAlign: "center" }}
                                >
                                    <span className={styles.msgUserName}>
                                        {storeMessageUser &&
                                            storeMessageUser.map(
                                                (message, counter) => {
                                                    return (
                                                        <span key={message.ID}>
                                                            {message.Name}
                                                            {(counter + 1 !== storeMessageUser.length && message.Name !== undefined) && (
                                                                <>, &nbsp;</>
                                                            )}
                                                        </span>
                                                    );
                                                }
                                            )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`${styles.messageContainer} position-relative`}
                                style={{ height: scrollbarHeight - 10 }}
                                id="messageContainer"
                            ></div>
                                <div className={styles.messageWriteContainer}>
                                        <Scrollbars
                                            autoHeight
                                            autoHeightMax={400}
                                            className={styles.spanTextArea}
                                        >
                                                <div className="inputkeyContainer">
                                                    <span
                                                        className={`${styles.spanSubjectTextArea} float-end`}
                                                        role="textbox"
                                                        ref={subjectElm}
                                                        onInput={handleSubjectChange}
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                    >{location?.state?.messageSubject}</span>
                                                    <span
                                                        className={`${styles.spanMsgTextArea} float-end`}
                                                        role="textbox"
                                                        ref={msgElm}
                                                        onInput={handleMessageChange}
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                    ></span>
                                                </div>
                                        </Scrollbars>
                                        <button
                                            className={`${styles.inputIcon} ${(msgInput && subjectInput) ? styles.active : ""
                                                } `}
                                        >
                                            <SlPaperPlane
                                                className={styles.sendMsg}
                                                onClick={((msgInput && subjectInput) ? () => handleMessageSubmission() : () => null)}
                                            />
                                        </button>
                                </div>
                            
                        </motion.div>
                    </>
                )}
            </Layout>
        </>
    );
};

export default Messages;
